<template>
  <a :class="disabled === true ? 'link-with-icon link-with-icon--disabled' : 'link-with-icon'"
     :href="href"
     :disabled="disabled"
     :target="target"
      @click="clickHandler">
    <svg-icon
      class="link-with-icon--icon"
      :icon-name="iconName"></svg-icon>
    <span><slot/></span>
  </a>
</template>

<script>
export default {
  name: 'LinkWithIcon',
  props: [
    'href',
    'disabled',
    'target',
    'iconName',
  ],
  methods: {
    clickHandler (e) {
      switch (this.disabled) {
        case true:
          e.preventDefault()
          break
        default:
          e.preventDefault()
          this.$emit('click')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .link-with-icon {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    text-decoration: none;
    color: $color-primary;
    transition: 0.2s ease color;

    &--icon {
      margin-right: 5px;
      fill: $color-primary;
      transition: 0.2s ease fill;

    }

    &:hover {
      color: $color-primary-hover;
    }

    &:hover &--icon {
      fill: $color-primary-hover;
    }

    &--disabled {
      cursor: auto;
      color: $color-disabled;

      &:hover {
        color: $color-disabled;
      }
    }

    &--disabled &--icon {
      fill: $color-disabled;
    }

    &--disabled:hover &--icon {
      fill: $color-disabled;
    }
  }
</style>
