export const ORDER_STATUS_ALL_SHIPMENTS = 0
export const ORDER_STATUS_PENDING_PROCESSING = 1
export const ORDER_STATUS_ACCEPTED_BY_SELLER = 2
export const ORDER_STATUS_CANCELLED_BY_SELLER = 3
export const ORDER_STATUS_SENT_TO_SM = 4
export const ORDER_STATUS_DELIVERED_TO_SM = 5
export const ORDER_STATUS_DELIVERING_TO_CLIENT = 6
export const ORDER_STATUS_DELIVERED_TO_CLIENT = 7

export default {
  1: 'Waiting for approval',
  2: 'Approved',
  3: 'Cancelled',
  4: 'Sent to SM warehouse',
  5: 'Delivered to SM warehouse',
  6: 'In transit',
  7: 'Delivered',
}
