<template>
  <table class="base-table">
    <thead>
      <tr class="base-table-head text-14-500">
        <slot name="header"></slot>
      </tr>
    </thead>
    <tbody>
      <slot name="body"></slot>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'BaseTable',
}
</script>

<style lang="scss">
  .base-table {
    width: 100%;
    text-align: left;
    border: 1px solid $color-line;

    tbody tr {
      transition: 0.2s ease background-color;
      background-color: #fff;

      &:hover {
        background-color: $color-active-blue;
      }
    }

    td {
      padding: 8px 12px;
      border-bottom: 1px solid $color-line;
      border-right: 1px solid #D4DADF;
    }

    &-head{
      border: 1px solid #D4DADF;
      border-radius: 4px 4px 0 0;
      background-color: #F3F5F7;
    }

    th {
      padding: 8px 12px;
      border-right: 1px solid #D4DADF;
    }
  }
</style>
