<template>
  <button
    class="base-button button"
    :class="{'base-button--disabled': disabled, 'base-button--transparent': transparent, 'base-button--secondary': secondary}"
    :disabled="disabled"
    @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: [
    'disabled',
    'transparent',
    'secondary',
  ],
}
</script>

<style lang="scss" scoped>
  .base-button {
    &--disabled {
      pointer-events: none;
    }

    &--secondary {
      border-color: $color-btn-secondary;
      color: $color-btn-secondary;
      background-color: transparent;
      line-height: 28px;

      &:hover {
        border-color: $color-btn-secondary-hover;
        background-color: transparent;
        color: $color-btn-secondary-hover;
      }
    }

    &--disabled.base-button--secondary {
      border-color: $color-btn-secondary-disabled;
      background-color: transparent;
      color: $color-btn-secondary-disabled;
    }
  }
</style>
