<template>
  <div
    class="product-image"
    :class="!url ? '__no-photo' : ''"
    :style="{ backgroundImage:  `url(${url})`, width: `${width}px`, height: `${height}px`}"
  >
    <svg-icon
      v-if="!url"
      class-name="product-image-no-photo"
      icon-name="no-photo"/>
  </div>
</template>

<script>
export default {
  name: 'ProductImage',
  props: {
    url: {
      type: String,
    },
    width: {
      type: Number,
      default: 60,
    },
    height: {
      type: Number,
      default: 60,
    },
  },
}
</script>

<style scoped lang="scss">
.product-image {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-line;
  background-position: center center;
  background-size: contain;
  position: relative;

  &.__no-photo {
    background-color: $color-background;
  }

  &-no-photo {
    position: absolute;
    width: 30px;
    height: 30px;
    fill: $color-grey-secondary;
  }
}
</style>
